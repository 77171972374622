import { PAGE_ACCESS } from '@/constants/access'

const protectRoute = (store, extra) => (to, _, next) => {
  const matched = to.matched[to.matched.length - 1]

  const pageAccess = { ...PAGE_ACCESS, ...extra }

  const getPageAccesses = pageAccess[to.path] || pageAccess[matched.path]

  const { getAccessRights } = store.getters

  if (getPageAccesses?.constructor === Function) {
    return getPageAccesses({ next, store })
  }

  if (getPageAccesses) {
    const shouldVisitPage = getPageAccesses.some((key) =>
      getAccessRights.includes(key)
    )

    if (!shouldVisitPage) {
      next('/')
      return
    }
  }

  next()
}

export { protectRoute }
