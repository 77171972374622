<template>
  <div class="c-boardAutocomplete">
    <api-auto-complete
      solo
      flat
      dense
      class="mt-0 ml-3"
      only-select
      :value="getBoard"
      :item-text="itemText"
      :fetch="fetchJobBoards"
      hide-details
      label="Job Boards"
      @click="onSelect"
    />
  </div>
</template>

<script>
import ApiAutoComplete from '@/components/ApiAutoComplete'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'board-auto-complete',
  components: {
    ApiAutoComplete,
  },
  data: () => ({ items: [] }),
  computed: {
    ...mapGetters(['getActiveBoard']),
    getBoard() {
      return this.getActiveBoard.jobBoardId
    },
  },
  methods: {
    ...mapActions(['setActiveBoardId']),
    fetchJobBoards(query) {
      return this.$http
        .get('/Operator/findJobBoards', {
          params: {
            jobBoardId: query,
          },
        })
        .then(({ data }) => {
          this.items = data
          return data
        })
    },

    itemText(value) {
      return value
    },

    onSelect(jobBoard) {
      if (this.items.includes(jobBoard)) {
        this.setActiveBoardId(jobBoard)

        this.$nextTick(() => {
          location.href = '/'
        })

        localStorage.setItem('jobBoardId', jobBoard)
      }
    },
  },
}
</script>

<style>
.c-boardAutocomplete {
  max-width: 180px;
}
</style>
