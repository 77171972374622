const env = (key) => {
  /* global defineEnv */
  const env = typeof defineEnv === 'object' ? defineEnv : {}

  return window?.ENV?.[key] || env?.[key]
}

const envVue = {
  install(Vue) {
    Vue.prototype.$env = env
  },
}

export { envVue, env }
