var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.getJobBoard)?_c('div',{style:(_vm.getJobBoard.subscriptionActive || _vm.getJobBoard.revenueShare
      ? ''
      : 'margin-top: 40px;')},[_c('vue-top-progress',{ref:"topProgress",attrs:{"color":"#3564FF","maximum":85,"minimum":60,"trickle":true}}),(!_vm.getJobBoard.subscriptionActive && !_vm.getJobBoard.revenueShare)?_c('div',{staticClass:"sub-banner"},[(_vm.getJobBoard.freeTrialActive)?_c('div',[_vm._v(" Free Trial: "+_vm._s(_vm.getJobBoard.freeTrialDaysLeft)+" Days Remaining ")]):_c('div',[_vm._v(" Your Job Board is Disabled Contact Us to Reactivate Your Job Board ")])]):_vm._e(),_c('v-navigation-drawer',{staticClass:"toolbar",style:(_vm.getJobBoard.subscriptionActive || _vm.getJobBoard.revenueShare
        ? ''
        : 'margin-top: 40px;'),attrs:{"permanent":"","mini-variant":_vm.miniVariant,"disable-resize-watcher":"","fixed":"","app":"","clipped":"","touchless":""}},[_c('v-list',{staticStyle:{"padding-top":"0px"}},_vm._l((_vm.items),function(item,i){return _c('v-tooltip',{key:i,attrs:{"right":"","open-delay":"0","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-list-item',_vm._g({attrs:{"to":'/' + item.route,"replace":"","exact":false,"color":"#1a1b25"}},on),[_c('v-list-item-action',[_c('v-icon',{staticClass:"material-icons-outlined",attrs:{"color":"#30333D"},domProps:{"innerHTML":_vm._s(item.icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.title)}})],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.title))])])}),1)],1),_c('v-app-bar',{staticStyle:{"background-color":"#fff"},style:(_vm.getJobBoard.subscriptionActive || _vm.getJobBoard.revenueShare
        ? ''
        : 'margin-top: 40px;'),attrs:{"fixed":"","app":"","clipped-left":"","height":"55px"}},[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/logo.svg"),"alt":"Job Board Fire","draggable":"false"}}),(!_vm.isOperatorAdmin)?_c('BoardAutoComplete'):_c('JobBoardSwitch',{attrs:{"id":_vm.getJobBoardId}}),_c('v-btn',{attrs:{"text":"","icon":"","href":_vm.getJobBoard.customDomain
          ? `https://${_vm.getJobBoard.customDomain}`
          : `https://${_vm.getJobBoard.id}.jobboardfire.com`,"target":"_blank","ripple":false,"plain":""},on:{"click":function($event){$event.stopPropagation();}}},[_c('v-icon',{attrs:{"color":"#51586a"}},[_vm._v("open_in_new")])],1),_c('JbMenu'),_c('UserDropdown')],1),_c('v-main',{staticClass:"main"},[_c('router-view')],1),(_vm.showProgressBar)?_c('div',[_c('transition',{attrs:{"name":"slide","mode":"out-in"}},[(_vm.stepIndex !== null)?_c('AccountProgressStepsModal',{attrs:{"step-index":_vm.stepIndex},on:{"hide":function($event){_vm.showProgressItems = false}}}):(_vm.showProgressItems)?_c('AccountProgressModal',{on:{"hide":function($event){_vm.showProgressItems = false}}}):_vm._e()],1),_c('CircleProgressBar',{model:{value:(_vm.showProgressItems),callback:function ($$v) {_vm.showProgressItems=$$v},expression:"showProgressItems"}})],1):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }