<template>
  <div @click="toggleModelValue" class="circleBar">
    <transition name="rotate" mode="out-in">
      <v-icon v-if="modelValue" large color="white" class="circleBar__arrow">
        expand_more
      </v-icon>
      <v-progress-circular
        v-else
        :rotate="90"
        :size="40"
        :width="2"
        :value="setupProgress"
        color="#fff"
        class="cicleBar__progress"
      >
        <LighteningIcon />
      </v-progress-circular>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LighteningIcon from '@/components/Icons/LighteningIcon.vue'

export default {
  name: 'CircleProgressBar',

  components: { LighteningIcon },

  model: {
    prop: 'show',
    event: 'change',
  },

  props: {
    show: Boolean,
  },

  computed: {
    ...mapGetters({
      setupProgress: 'progress/setupProgress',
    }),

    modelValue: {
      get() {
        return this.show
      },
      set(val) {
        this.$emit('change', val)
      },
    },
  },

  methods: {
    toggleModelValue() {
      this.modelValue = !this.modelValue
    },
  },
}
</script>

<style>
.circleBar {
  position: fixed;
  right: 100px;
  bottom: 20px;
  width: 48px;
  height: 48px;
  z-index: 200;
  background: #3564ff;
  box-shadow: -2px 4px 4px rgba(0, 0, 0, 0.06), 2px 4px 4px rgba(0, 0, 0, 0.06);
  padding: 6px;
  border-radius: 50%;
  cursor: pointer;
}
.circleBar__arrow,
.cicleBar__progress {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.circleBar .v-progress-circular__underlay {
  stroke: #688aff;
}
.rotate-enter-active,
.rotate-leave-active {
  transition: all 300ms ease-in;
}
.rotate-leave-to {
  animation: rotateOut 300ms ease-out;
}
.rotate-enter {
  opacity: 0;
  transform: translate(-50%, -50%) rotate(-90deg) scale(0.9);
}
@keyframes rotateOut {
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) rotate(90deg) scale(0.9);
  }
}
</style>
