import Pusher from 'pusher-js'
import store from '@/store'

export const pusherAuth = {
  data: () => ({
    pusher: null,
    channel: null,
  }),

  methods: {
    $_pusher_auth({ token, jobBoardId }) {
      Pusher.logToConsole = process.env.NODE_ENV !== 'production'

      this.pusher = new Pusher('f2d333bfdf80b83dd504', {
        cluster: 'us3',
        channelAuthorization: {
          endpoint:
            'https://job-board-fire-dev.azurewebsites.net/api/pusher/auth',
          headers: {
            jobBoardId,
            Authorization: token,
          },
        },
      })

      this.channel = this.pusher.subscribe('onboarding')

      this.channel.bind('new-onboarding-passed-step', this.handleOnBoarding)
    },

    handleOnBoarding(data) {
      store.commit('progress/setPassStep', data)
    },

    $_pusher_disconnect() {
      if (this.channel && this.pusher) {
        this.channel.unbind()
        this.pusher.disconnect()
      }
    },
  },
}
