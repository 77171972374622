import Vue from 'vue'
import App from './App'
import router from './router'
import store from '@/store'
import '@/assets/css/main.css'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueAuth from '@websanova/vue-auth/dist/v2/vue-auth.common.js'
import moment from 'moment/min/moment.min'
// import VueAnalytics from 'vue-analytics'
import money from 'v-money'
import Default from '@/layouts/Default'
import NoNavigation from '@/layouts/NoNavigation'
import LogRocket from 'logrocket'
import { parseUserData } from '@/util/parseUserData'
import { protectRoute } from '@/util/protectRoute'
import vuetify from './plugins/vuetify'
import VueMask from 'v-mask'
import userAvailablePage from '@/middlewares/userAvailablePage'
import hasJobAggregationFeatureAccess from '@/middlewares/hasJobAggregationFeatureAccess'
import Btn from '@/components/UI/Btn'
import VueMeta from 'vue-meta'
import { envVue, env } from '@/helpers/env'
import posthog from 'posthog-js'

Vue.use(VueMeta)
Vue.use(envVue)

Vue.component('default-layout', Default)
Vue.component('no-navigation-layout', NoNavigation)
Vue.component('btn', Btn)

Vue.use(VueMask, {
  placeholders: {
    N: /^[\w-_.]*$/,
  },
})

Vue.use(VueAxios, axios)

Vue.axios.defaults.baseURL = env('API_BASE')

Vue.router = router

Vue.use(VueAuth, {
  plugins: {
    http: Vue.axios,
    router: Vue.router,
  },
  drivers: {
    auth: require('@websanova/vue-auth/dist/drivers/auth/bearer.js'),
    http: require('@websanova/vue-auth/dist/drivers/http/axios.1.x.js'), // Axios
    router: require('@websanova/vue-auth/dist/drivers/router/vue-router.2.x.js'),
  },
  options: {
    loginData: {
      url: 'Auth/Token',
      method: 'POST',
      redirect: '/',
    },
    fetchData: {
      url: '/Operator/Auth/Me',
      method: 'GET',
      enabled: true,
    },
    token: [
      {
        request: 'Authorization',
        response: 'Authorization',
        authType: 'bearer',
        foundIn: 'header',
      },
    ],
    refreshData: {
      enabled: false,
    },
    parseUserData: parseUserData(store),
    authRedirect: { path: '/signin' },
  },
})

axios.interceptors.response.use(
  function (response) {
    const { authorization } = response.headers

    if (authorization) {
      Vue.auth.token(null, authorization, false)
    }

    return response
  },
  (error) => {
    if (error.response.status === 403) {
      Vue.auth.logout({
        makeRequest: false,
        redirect: `/signin?next=${location.pathname}`,
      })

      store.dispatch('setJobBoard', null)
    }

    return Promise.reject(error)
  }
)

axios.interceptors.request.use(
  function (config) {
    const { jobBoardId } = vueInstance.$auth.user() || { jobBoardId: null }
    if (jobBoardId && !config.headers.jobBoardId) {
      config.headers.jobBoardId = jobBoardId
    }

    return config
  },
  function (err) {
    return Promise.reject(err)
  }
)

Vue.prototype.$moment = moment

Vue.config.productionTip = false

// TODO: Create ga property for dashboard
// Vue.use(VueAnalytics, {
//   id: 'UA-128202052-1',
//   linkers: ['jobboardfire.com'],
//   router
// })

Vue.use(money, { decimal: '.', thousands: '', precision: 2 })

router.beforeEach(
  protectRoute(store, {
    '/home': (args) => userAvailablePage(args),
    '/preferences/aggregation-rules': (args) =>
      hasJobAggregationFeatureAccess(args),
    '/preferences/aggregation-filters-mapping': (args) =>
      hasJobAggregationFeatureAccess(args),
  })
)

router.onError((error) => {
  if (/(ChunkLoadError|loading css chunk)/i.test(error)) {
    location.reload(true)
  }
})

router.afterEach((to) => {
  if (vueInstance.$posthog) {
    vueInstance.$nextTick(() => {
      vueInstance.$posthog.capture('$pageview', {
        $current_url: window.location.origin + to.fullPath,
      })
    })
  }
})

const vueInstance = new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')

Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.prototype.$S3BaseURL = env('S3_BASE_URL')
Vue.prototype.$CustomS3BaseURL = env('CUSTOM_S3_BASE_URL')

const hostname = window && window.location && window.location.hostname

if (
  !(/^.*localhost.*/.test(hostname) || /my-dev.jobboardfire.com/.test(hostname))
) {
  Vue.prototype.$posthog = posthog.init(
    'phc_4opcxJLdNwDTajUCDqIzv44k26fsGG0mVy8kNdhgL4w',
    {
      api_host: 'https://experiment-api.jobboardfire.com',
    }
  )
}

LogRocket.init('phzi93/my-job-board-fire')
