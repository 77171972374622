// Resource manager
export const JOB_POST_READ = 1
export const JOB_POST_FULL = 2
export const USER_READ = 21
export const USER_FULL = 20
export const COMPANY_READ = 3
export const COMPANY_FULL = 4
export const JOB_ALERT_READ = 5
export const JOB_ALERT_FULL = 6
export const APPLICATION_READ = 22

// Preferences
export const APPEARANCE_ACCESS = 7
export const GENERAL_ACCESS = 8
export const FIELDS_ACCESS = 9
export const CATEGORIES_AND_TAGS_ACCESS = 10
export const JOB_ALERTS_ACCESS = 11
export const ECOMMERCE_ACCESS = 12
export const BACKFILLED_JOBS_ACCESS = 13
export const LEGAL_ACCESS = 14
export const LOCALIZATION_ACCESS = 15
export const CUSTOM_CODE_ACCESS = 16
export const ROLES_ACCESS = 17
export const USERS_ACCESS = 18
export const OAUTH_ACCESS = 24
export const AUTO_IMPORT_ACCESS = 25
export const TALENT_NETWORK_SETTINGS_ACCESS = 28

export const FINANCIAL_READ = 26
export const FINANCIAL_FULL_ACCESS = 27

export const RESOURCE_MANAGER_ACCESS = {
  '/jobs': [JOB_POST_READ, JOB_POST_FULL],
  '/companies': [COMPANY_READ, COMPANY_FULL],
  '/users': [USER_READ, USER_FULL],
  '/alerts': [JOB_ALERT_READ, JOB_ALERT_FULL],
  '/applications': [APPLICATION_READ],
  '/financials': [FINANCIAL_READ, FINANCIAL_FULL_ACCESS],
}

export const PREFERENCE_ACCESS_LIST = [
  APPEARANCE_ACCESS,
  GENERAL_ACCESS,
  FIELDS_ACCESS,
  CATEGORIES_AND_TAGS_ACCESS,
  JOB_ALERTS_ACCESS,
  ECOMMERCE_ACCESS,
  BACKFILLED_JOBS_ACCESS,
  LEGAL_ACCESS,
  LOCALIZATION_ACCESS,
  CUSTOM_CODE_ACCESS,
  USERS_ACCESS,
  ROLES_ACCESS,
  OAUTH_ACCESS,
  TALENT_NETWORK_SETTINGS_ACCESS,
]

export const PAGE_ACCESS = {
  '/financials': [FINANCIAL_READ, FINANCIAL_FULL_ACCESS],
  '/financials/orders': [FINANCIAL_READ, FINANCIAL_FULL_ACCESS],
  '/financials/subscriptions': [FINANCIAL_READ, FINANCIAL_FULL_ACCESS],
  '/applications': [APPLICATION_READ],
  '/applications/:id': [APPLICATION_READ],
  '/jobs': [JOB_POST_READ, JOB_POST_FULL],
  '/jobs/:jobId/job': [JOB_POST_READ, JOB_POST_FULL],
  '/jobs/new/job': [JOB_POST_FULL],
  '/jobs/:jobId/subscribers': [JOB_POST_READ, JOB_POST_FULL],
  '/companies': [COMPANY_READ, COMPANY_FULL],
  '/companies/:companyId/profile': [COMPANY_READ, COMPANY_FULL],
  '/companies/:companyId/team': [COMPANY_READ, COMPANY_FULL],
  '/companies/new/profile': [COMPANY_FULL],
  '/users': [USER_READ, USER_FULL],
  '/users/:userId': [USER_READ, USER_FULL],
  '/users/new': [USER_FULL],
  '/alerts': [JOB_ALERT_READ, JOB_ALERT_FULL],
  '/alerts/:alertId': [JOB_ALERT_READ, JOB_ALERT_FULL],
  '/alerts/new': [JOB_ALERT_FULL],
  '/preferences': PREFERENCE_ACCESS_LIST,
  '/preferences/appearance': [APPEARANCE_ACCESS],
  '/preferences/general': [GENERAL_ACCESS],
  '/preferences/fields': [FIELDS_ACCESS],
  '/preferences/fields/:formName/:formId/:fieldId': [FIELDS_ACCESS],
  '/preferences/fields/:formName': [FIELDS_ACCESS],
  '/preferences/categories': [CATEGORIES_AND_TAGS_ACCESS],
  '/preferences/alerts': [JOB_ALERTS_ACCESS],
  '/preferences/products': [ECOMMERCE_ACCESS],
  '/preferences/products/:id': [ECOMMERCE_ACCESS],
  '/preferences/billing': [ECOMMERCE_ACCESS],
  '/preferences/upsells': [ECOMMERCE_ACCESS],
  '/preferences/upsells/:id': [ECOMMERCE_ACCESS],
  '/preferences/promotion-code': [ECOMMERCE_ACCESS],
  '/preferences/promotion-code/:id': [ECOMMERCE_ACCESS],
  '/preferences/backfill': [BACKFILLED_JOBS_ACCESS],
  '/preferences/legal': [LEGAL_ACCESS],
  '/preferences/localization': [LOCALIZATION_ACCESS],
  '/preferences/code': [CUSTOM_CODE_ACCESS],
  '/preferences/team/users': [USERS_ACCESS],
  '/preferences/team/users/:id': [USERS_ACCESS],
  '/preferences/team/roles': [ROLES_ACCESS],
  '/preferences/team/roles/:id': [ROLES_ACCESS],
  '/preferences/team': [USERS_ACCESS, ROLES_ACCESS],
  '/preferences/identity': [OAUTH_ACCESS],
  '/preferences/talent-network': [TALENT_NETWORK_SETTINGS_ACCESS],
}

export const ACCESS_EVERYTHING = [
  JOB_POST_READ,
  JOB_POST_FULL,
  USER_READ,
  USER_FULL,
  COMPANY_READ,
  COMPANY_FULL,
  JOB_ALERT_READ,
  JOB_ALERT_FULL,
  APPLICATION_READ,
  FINANCIAL_READ,
  FINANCIAL_FULL_ACCESS,
  ...PREFERENCE_ACCESS_LIST,
]
