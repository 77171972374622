<template>
  <v-app
    v-if="$auth.ready()"
    :class="{
      'has-subscribe-banner':
        getJobBoard &&
        !getJobBoard.subscriptionActive &&
        !getJobBoard.revenueShare,
    }"
  >
    <component :is="layout">
      <router-view />
    </component>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['getJobBoard']),
    layout() {
      return (this.$route.meta.layout || 'default') + '-layout'
    },
    authUser() {
      return this.$auth.user()
    },
  },
  watch: {
    authUser: {
      deep: true,
      handler: async function (newValue) {
        if (newValue == null) {
          if (window.Intercom) {
            window.Intercom('shutdown')
          }

          this.$posthog?.reset()
        }
      },
    },
  },
}
</script>
