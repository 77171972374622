import axios from 'axios'
import { FIELD_TYPE } from '@/constants/fields'

const SINGLE_FIELDS = [
  FIELD_TYPE.SocialSharingImageFieldType,
  FIELD_TYPE.CompanyCoverImageFieldType,
]

const filterSingleFields = (field) =>
  SINGLE_FIELDS.includes(field.formFieldType)

const fieldList = {
  namespaced: true,
  state: {
    data: {
      fields: [],
      formId: null,
    },
    isLoading: true,
    isSorting: false,
  },
  mutations: {
    create(state, payload) {
      state.data = payload
    },
    loading(state, payload) {
      state.isLoading = payload
    },
    sort(state, payload) {
      state.isSorting = payload
    },
  },
  actions: {
    fetch({ commit }, { formId }) {
      commit('loading', true)

      return axios
        .get(`/Operator/FormDefinitions/${formId}`)
        .then((res) => {
          commit('create', res.data)
          commit('loading', false)
        })
        .catch(() => {
          commit('loading', false)
        })
    },
    async sort({ commit, getters }, payload) {
      commit('sort', true)

      const { getFormId } = getters

      await axios.put(
        `/Operator/FormDefinitions/${getFormId}/MoveField`,
        payload
      )
      commit('sort', false)
    },
  },
  getters: {
    isReady: (state) => !state.isLoading,
    isSorting: (state) => state.isSorting,
    getFields: (state) => state.data.fields,
    getFormId: (state) => state.data.formId,
    getSingleFields: (state) =>
      state.data.fields
        .filter(filterSingleFields)
        .map((value) => value.formFieldType),
  },
}

export default fieldList
