<template>
  <div class="modalWrapper overflow-y-auto">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ModalWrapper',
}
</script>

<style>
.modalWrapper {
  padding: 12px 0;
  width: 270px;
  position: fixed;
  right: 20px;
  bottom: 100px;
  background: #ffffff;
  border: 1.5px solid #3564ff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1), 0px 2px 6px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  max-height: calc(100% - 100px - 100px);
  z-index: 1;
}
</style>
