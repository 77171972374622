import Vue from 'vue'
import Vuex from 'vuex'
import http from 'axios'
import fieldList from './modules/fieldList'
import progress from './modules/progress'
import { EventBus } from '@/util/eventBus.js'
import { OPERATOR } from '@/constants/operator'
import { currencyFormat, currencyLocales } from '@/mixins/GetPriceString'
import { ACCESS_EVERYTHING } from '@/constants/access'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    fieldList,
    progress,
  },
  state: {
    operatorType: null,
    jobBoard: null,
    ecommercePreferences: null,
    jobBoards: [],
    activeJobBoardId: null,
  },
  getters: {
    isOperatorSales: (state) => state.operatorType === OPERATOR.SALES,
    isOperatorSuper: (state) => state.operatorType === OPERATOR.SUPER_ADMIN,
    isOperatorAdmin: (state) => state.operatorType === OPERATOR.ADMIN,
    getJobBoard: (state) => state.jobBoard,
    getActiveBoard: ({ operatorType, jobBoards, activeJobBoardId }) => {
      if (operatorType !== OPERATOR.ADMIN) {
        return { jobBoardId: activeJobBoardId }
      }

      const [getDefaultBoard] = jobBoards

      if (!getDefaultBoard && !activeJobBoardId) {
        return {}
      }

      const jobBoardId = activeJobBoardId || getDefaultBoard.jobBoardId

      return jobBoards.find((board) => board.jobBoardId === jobBoardId)
    },
    getAccessRights: (_, getter) => {
      if (getter.isOperatorSuper || getter.isOperatorSales) {
        return ACCESS_EVERYTHING
      }

      return getter.getActiveBoard.privilegesbyRoleId
    },
    getEcommercePreferences: (state) => {
      if (state.ecommercePreferences) {
        return {
          ...state.ecommercePreferences,
          currencyLocale: currencyLocales[state.ecommercePreferences.currency],
          currencyFormat: currencyFormat[state.ecommercePreferences.currency],
        }
      }

      return null
    },
    getJobBoards: (state) => state.jobBoards,
  },
  mutations: {
    setOperatorType(state, operatorType) {
      state.operatorType = operatorType
    },
    setJobBoard(state, jobBoard) {
      state.jobBoard = jobBoard
    },
    setJobBoards(state, jobBoards) {
      state.jobBoards = jobBoards
    },
    setActiveBoardId(state, id) {
      state.activeJobBoardId = id
    },
    setEcommercePreferences(state, ecommercePreferences) {
      state.ecommercePreferences = ecommercePreferences
    },
    updateStateByKey(state, { key, value }) {
      state.jobBoard[key] = value
    },
  },
  actions: {
    fetchJobBoard({ commit }) {
      const { $auth } = this._vm

      if ($auth.check()) {
        return http
          .get(`/JobBoards/${$auth.user().jobBoardId}/7eaeb3bb`)
          .then((res) => {
            commit('setJobBoard', res.data)
          })
      }
    },
    fetchEcommercePreferences({ commit }) {
      commit('setEcommercePreferences', null)

      return http
        .get('/Operator/Ecommerce')
        .then((res) => {
          commit('setEcommercePreferences', res.data)
          return Promise.resolve(res.data)
        })
        .finally(() => {
          EventBus.$emit('stopTopProgress')
        })
    },
    setOperatorType({ commit }, operatorType) {
      commit('setOperatorType', operatorType)
    },
    setEcommercePreferences({ commit }, ecommercePreferences) {
      commit('setEcommercePreferences', ecommercePreferences)
    },
    setJobBoard({ commit }, data) {
      commit('setJobBoard', data)
    },
    setJobBoards({ commit }, jobBoards) {
      commit('setJobBoards', jobBoards)
    },
    setActiveBoardId({ commit }, jobBoardId) {
      commit('setActiveBoardId', jobBoardId)
    },
  },
})
