<template>
  <v-menu content-class="userDropdown" offset-y nudge-bottom="10">
    <template v-slot:activator="{ on, attrs }">
      <jb-btn text v-bind="attrs" v-on="on" class="userDropdown__button d-flex">
        <v-icon class="material-icons-outlined mr-1">person_outline</v-icon>
        {{ getFullName }}
      </jb-btn>
    </template>
    <list>
      <list-item to="/account"> Account </list-item>
      <list-item @click.native="signOut"> Sign Out </list-item>
    </list>
  </v-menu>
</template>

<script>
import List from '@/components/List/List'
import ListItem from '@/components/List/ListItem'
import { mapActions } from 'vuex'
import JbBtn from '@/components/JbBtn/JbBtn'

export default {
  name: 'UserDropdown',
  components: {
    List,
    ListItem,
    JbBtn,
  },
  computed: {
    getFullName() {
      return `${this.$auth.user().firstName} ${this.$auth.user().lastName}`
    },
  },
  methods: {
    ...mapActions(['setJobBoard']),

    signOut() {
      this.$auth.logout({
        makeRequest: false,
        redirect: '/signin',
      })

      this.setJobBoard(null)
    },
  },
}
</script>

<style>
.userDropdown {
  cursor: pointer;
}

.userDropdown__button {
}
</style>
