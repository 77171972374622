import { OPERATOR } from '@/constants/operator'

const isBoardExists = (id, jobBoards) =>
  jobBoards.find((board) => board.jobBoardId === id)

function noop() {}

const parseUserData =
  (store, callback = noop) =>
  (user) => {
    if (!user.jobBoardPrivileges) {
      if (location.pathname !== '/user-without-job-board') {
        location.href = '/user-without-job-board'
      }

      return { ...user, jobBoardPrivileges: [] }
    }

    const { accountType } = user

    store.dispatch('setOperatorType', accountType)
    store.dispatch('setJobBoards', user.jobBoardPrivileges)

    const [{ jobBoardId: defaultJobBoardId }] = user.jobBoardPrivileges

    const syncJobBoardId = localStorage.getItem('jobBoardId')
    const isSuperOperator =
      accountType === OPERATOR.SUPER_ADMIN || accountType === OPERATOR.SALES
    const shouldCheckBoard =
      isSuperOperator || isBoardExists(syncJobBoardId, user.jobBoardPrivileges)

    if (syncJobBoardId && shouldCheckBoard) {
      store.dispatch('setActiveBoardId', syncJobBoardId)

      const newUser = {
        ...user,
        jobBoardId: syncJobBoardId,
      }

      callback(newUser)

      return newUser
    } else {
      store.dispatch('setActiveBoardId', defaultJobBoardId)
      localStorage.setItem('jobBoardId', defaultJobBoardId)
    }

    const newUser = { ...user, jobBoardId: defaultJobBoardId }

    callback(newUser)

    return newUser
  }

export { parseUserData }
