<template>
  <v-dialog :value="isOpen" width="400" @input="handleModalClose">
    <v-form v-model="isFormValid" ref="form">
      <v-card class="pa-3">
        <v-card-title class="headline pl-0">Register</v-card-title>

        <v-text-field
          :value="value"
          @input="handleInput"
          name="jobBoardName"
          autocomplete="job-board-name"
          label="Job board name"
          required
          :disabled="loading"
          suffix=".jobboardfire.com"
          hint="You can't change this later, but you can set up a custom domain name."
          persistent-hint
          :counter="50"
          :error-messages="errors"
          :rules="jobBoardIdRules"
          @focus="handleFocus"
        ></v-text-field>

        <btn
          name="sign-up"
          large
          block
          class="mt-4 text-nromalize"
          color="primary"
          default
          :loading="loading"
          @click="handleCreate"
          :disabled="shouldDisable"
        >
          Create job board
        </btn>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: 'create-jobboard-modal',
  props: {
    isOpen: Boolean,
    loading: Boolean,
    errors: Array,
    value: String,
  },
  data: () => ({
    isFormValid: false,
    jobBoardIdRules: [
      (v) => !!v || 'Job board name is required',
      (v) =>
        (v.length > 2 && v.length < 51) || 'Must be between 3-50 letters long',
      (v) =>
        /^[a-zA-Z]+$/.test(v) ||
        'Must be one word, no numbers or special characters',
    ],
  }),
  computed: {
    shouldDisable() {
      return this.loading || !this.isFormValid || this.hasCreated
    },
  },
  methods: {
    handleFocus() {
      this.$emit('clear-errors')
    },
    handleCreate() {
      this.$emit('create')
    },
    handleModalClose() {
      this.$refs.form.resetValidation()
      this.handleInput('')
      this.$emit('clear-errors')
      this.$emit('close-modal')
    },
    handleInput(value) {
      this.$emit('input', value)
    },
  },
}
</script>
