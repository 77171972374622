<template>
  <v-menu
    offset-y
    :close-on-content-click="false"
    nudge-bottom="10"
    max-height="400px"
    max-width="400px"
  >
    <template v-slot:activator="{ on, attrs }">
      <jb-btn
        text
        v-bind="attrs"
        v-on="on"
        :icon="$vuetify.breakpoint.sm"
        @click.native="onClick"
        class="text-none ml-auto mr-1 font-weight-medium"
      >
        <v-badge :value="showUpdate" dot offset-x="15" offset-y="8">
          <v-icon
            class="material-icons-outlined"
            :class="{ 'changeLog__bell--animate': showUpdate }"
          >
            notifications
          </v-icon>
        </v-badge>
        <span v-if="$vuetify.breakpoint.mdAndUp" class="ml-1"> Updates </span>
      </jb-btn>
    </template>

    <ChangeLog :change-logs="changeLogs" />
  </v-menu>
</template>

<script>
import ChangeLog from '@/components/NavBar/ChangeLog/ChangeLog'
import JbBtn from '@/components/JbBtn/JbBtn'
import { changeLogs } from '@/changelog.json'

export default {
  name: 'change-log-bell',
  components: {
    ChangeLog,
    JbBtn,
  },
  data: () => ({
    changeLogs,
    showUpdate: false,
  }),
  created() {
    const getLogs = localStorage.getItem('changeLogs')

    this.showUpdate = getLogs !== JSON.stringify(changeLogs)
  },
  methods: {
    onClick() {
      localStorage.setItem('changeLogs', JSON.stringify(changeLogs))

      this.showUpdate = false
    },
  },
}
</script>

<style>
.changeLog__bell--animate {
  animation: bell-ring 4s 0.7s ease-in-out infinite;
  transform-origin: 50% 4px;
}

@keyframes bell-ring {
  0% {
    transform: rotate(0);
  }
  1% {
    transform: rotate(30deg);
  }
  3% {
    transform: rotate(-28deg);
  }
  5% {
    transform: rotate(34deg);
  }
  7% {
    transform: rotate(-32deg);
  }
  9% {
    transform: rotate(30deg);
  }
  11% {
    transform: rotate(-28deg);
  }
  13% {
    transform: rotate(26deg);
  }
  15% {
    transform: rotate(-24deg);
  }
  17% {
    transform: rotate(22deg);
  }
  19% {
    transform: rotate(-20deg);
  }
  21% {
    transform: rotate(18deg);
  }
  23% {
    transform: rotate(-16deg);
  }
  25% {
    transform: rotate(14deg);
  }
  27% {
    transform: rotate(-12deg);
  }
  29% {
    transform: rotate(10deg);
  }
  31% {
    transform: rotate(-8deg);
  }
  33% {
    transform: rotate(6deg);
  }
  35% {
    transform: rotate(-4deg);
  }
  37% {
    transform: rotate(2deg);
  }
  39% {
    transform: rotate(-1deg);
  }
  41% {
    transform: rotate(1deg);
  }
  43% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}
</style>
