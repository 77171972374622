<template>
  <component
    :is="getComponentType"
    class="c-listItem d-flex font-weight-medium text-decoration-none"
    v-bind="getProps"
  >
    <span class="c-listItem__icon d-flex align-center mr-3">
      <slot name="icon" />
    </span>

    <span class="c-listItem__text">
      <slot />
    </span>
  </component>
</template>

<script>
export default {
  name: 'ListItem',
  props: {
    href: String,
    to: String,
  },
  computed: {
    getProps() {
      if (this.href) {
        return {
          href: this.href,
        }
      }

      if (this.to) {
        return {
          to: this.to,
        }
      }

      return {}
    },
    getComponentType() {
      if (this.href) {
        return 'a'
      }

      if (this.to) {
        return 'router-link'
      }

      return 'button'
    },
  },
}
</script>

<style>
.c-listItem {
  width: 100%;
  border-radius: 4px;
  height: 34px;
  align-items: center;
  padding: 4px 10px;
  transition: background 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  color: #51586a !important;
}

.c-listItem:hover {
  cursor: pointer;
  background: #f5f5f5;
}

.c-listItem__text {
  font-size: 14px;
}

.c-listItem__icon .v-icon {
  font-size: 20px;
}

.c-listItem__icon:empty {
  margin: 0 !important;
}
</style>
