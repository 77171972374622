<template>
  <div
    v-if="getJobBoard"
    :style="
      getJobBoard.subscriptionActive || getJobBoard.revenueShare
        ? ''
        : 'margin-top: 40px;'
    "
  >
    <vue-top-progress
      ref="topProgress"
      color="#3564FF"
      :maximum="85"
      :minimum="60"
      :trickle="true"
    />

    <div
      v-if="!getJobBoard.subscriptionActive && !getJobBoard.revenueShare"
      class="sub-banner"
    >
      <div v-if="getJobBoard.freeTrialActive">
        Free Trial: {{ getJobBoard.freeTrialDaysLeft }} Days Remaining
      </div>
      <div v-else>
        Your Job Board is Disabled Contact Us to Reactivate Your Job Board
      </div>
    </div>

    <v-navigation-drawer
      class="toolbar"
      permanent
      :mini-variant="miniVariant"
      disable-resize-watcher
      fixed
      app
      clipped
      touchless
      :style="
        getJobBoard.subscriptionActive || getJobBoard.revenueShare
          ? ''
          : 'margin-top: 40px;'
      "
    >
      <v-list style="padding-top: 0px">
        <v-tooltip
          v-for="(item, i) in items"
          :key="i"
          right
          open-delay="0"
          color="primary"
        >
          <template v-slot:activator="{ on }">
            <v-list-item
              :to="'/' + item.route"
              replace
              :exact="false"
              v-on="on"
              color="#1a1b25"
            >
              <v-list-item-action>
                <v-icon
                  class="material-icons-outlined"
                  v-html="item.icon"
                  color="#30333D"
                ></v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <span>{{ item.title }}</span>
        </v-tooltip>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      fixed
      app
      clipped-left
      style="background-color: #fff"
      height="55px"
      :style="
        getJobBoard.subscriptionActive || getJobBoard.revenueShare
          ? ''
          : 'margin-top: 40px;'
      "
    >
      <img
        class="logo"
        src="@/assets/logo.svg"
        alt="Job Board Fire"
        draggable="false"
      />

      <BoardAutoComplete v-if="!isOperatorAdmin" />
      <JobBoardSwitch v-else :id="getJobBoardId" />
      <v-btn
        text
        icon
        :href="
          getJobBoard.customDomain
            ? `https://${getJobBoard.customDomain}`
            : `https://${getJobBoard.id}.jobboardfire.com`
        "
        target="_blank"
        :ripple="false"
        plain
        @click.stop
      >
        <v-icon color="#51586a">open_in_new</v-icon>
      </v-btn>

      <JbMenu />
      <UserDropdown />
    </v-app-bar>
    <v-main class="main">
      <router-view />
    </v-main>

    <div v-if="showProgressBar">
      <transition name="slide" mode="out-in">
        <AccountProgressStepsModal
          v-if="stepIndex !== null"
          :step-index="stepIndex"
          @hide="showProgressItems = false"
        />
        <AccountProgressModal
          v-else-if="showProgressItems"
          @hide="showProgressItems = false"
        />
      </transition>
      <CircleProgressBar v-model="showProgressItems" />
    </div>
  </div>
</template>

<script>
import { vueTopprogress as VueTopProgress } from 'vue-top-progress'
import { EventBus } from '@/util/eventBus.js'
import { mapGetters, mapActions, mapState } from 'vuex'
import VueScrollTo from 'vue-scrollto'
import LogRocket from 'logrocket'
import { ECOMMERCE_ACCESS, PAGE_ACCESS } from '@/constants/access'
import JobBoardSwitch from '@/components/NavBar/JobBoardSwitch/JobBoardSwitch'
import BoardAutoComplete from '@/components/NavBar/BoardAutoComplete/BoardAutoComplete'
import JbMenu from '@/components/NavBar/Menu/JbMenu'
import UserDropdown from '@/components/NavBar/UserDropdown/UserDropdown'
import { bootIntercom } from '@/helpers/intercom'
import CircleProgressBar from '@/components/AccountProgressModal/CircleProgressBar'
import AccountProgressModal from '@/components/AccountProgressModal/AccountProgressModal'
import AccountProgressStepsModal from '@/components/AccountProgressModal/AccountProgressStepsModal'
import { pusherAuth } from '@/mixins/pusherAuth'

export default {
  name: 'App',
  components: {
    AccountProgressStepsModal,
    AccountProgressModal,
    CircleProgressBar,
    VueTopProgress,
    JobBoardSwitch,
    BoardAutoComplete,
    JbMenu,
    UserDropdown,
  },
  mixins: [pusherAuth],
  data() {
    return {
      drawer: true,
      miniVariant: true,
      right: true,
      showProgressItems: false,
      timeout: null,
      isLoading: false,
    }
  },
  metaInfo() {
    let titleTemplate = 'Job Board Fire'

    if (this.getJobBoard) {
      titleTemplate = `%s - ${this.getJobBoard.name} - Job Board Fire`
    }

    return {
      titleTemplate,
    }
  },
  computed: {
    ...mapState({
      stepIndex: (state) => state.progress.stepIndex,
    }),
    ...mapGetters([
      'getJobBoard',
      'getActiveBoard',
      'getAccessRights',
      'isOperatorAdmin',
    ]),
    ...mapGetters({
      isCompleted: 'progress/isCompleted',
    }),
    showProgressBar() {
      return this.$route.name !== 'Home' && !this.isLoading && !this.isCompleted
    },
    getJobBoardId() {
      return this.getActiveBoard.jobBoardId
    },
    items() {
      let items = [
        {
          icon: 'savings',
          title: 'Financials',
          route: 'financials',
        },
        {
          icon: 'work_outline',
          title: 'Jobs',
          route: 'jobs',
        },
        {
          icon: 'business',
          title: 'Companies',
          route: 'companies',
        },
        {
          icon: 'person_outline',
          title: 'Users',
          route: 'users',
        },
        {
          icon: 'description',
          title: 'Applications',
          route: 'applications',
        },
        {
          icon: 'email',
          title: 'Job alerts',
          route: 'alerts',
        },
        {
          icon: 'settings',
          title: 'Preferences',
          route: 'preferences',
        },
      ]

      if (!this.isCompleted) {
        items.unshift({
          icon: 'home',
          title: 'Home',
          route: 'home',
        })
      }

      items = items.filter((value) => {
        const getRouteKeys = PAGE_ACCESS[`/${value.route}`]

        if (getRouteKeys) {
          return getRouteKeys.some((key) => this.getAccessRights.includes(key))
        }

        return true
      })

      return items
    },
  },
  async created() {
    try {
      this.isLoading = true

      await this.fetchJobBoard()

      if (this.$auth.check()) {
        const requests = [this.$store.dispatch('progress/fetchPassedSteps')]

        if (this.getAccessRights.includes(ECOMMERCE_ACCESS)) {
          requests.push(this.fetchEcommercePreferences())
        }

        await Promise.allSettled(requests)
      }

      if (process.env.NODE_ENV !== 'development') {
        if (this.$auth.check() && this.isOperatorAdmin) {
          bootIntercom(this.$auth.user(), this.getJobBoard)
        }

        window.Intercom('update', {
          hide_default_launcher: !this.isOperatorAdmin,
        })
      }

      EventBus.$on('startTopProgress', this.startTopProgress)
      EventBus.$on('stopTopProgress', this.stopTopProgress)
      EventBus.$on('goToRouteAndElement', this.handleGoToRoute)
    } finally {
      this.isLoading = false
    }
  },
  beforeDestroy() {
    EventBus.$off('startTopProgress', this.startTopProgress)
    EventBus.$off('stopTopProgress', this.stopTopProgress)
    EventBus.$off('goToRouteAndElement', this.handleGoToRoute)
  },
  mounted() {
    const hostname = window && window.location && window.location.hostname

    if (this.$auth.check()) {
      this.$_pusher_auth({
        token: this.$auth.token(),
        jobBoardId: this.$auth.user().jobBoardId,
      })
    }

    if (
      !(
        /^.*localhost.*/.test(hostname) ||
        /my-dev.jobboardfire.com/.test(hostname)
      )
    ) {
      if (this.$auth.check()) {
        bootIntercom(this.$auth.user(), this.getJobBoard)

        this.$posthog.identify(this.$auth.user().id, {
          email: this.$auth.user().email,
        })

        this.$posthog.group('job-board', this.getJobBoard.id, {
          name: this.getJobBoard.name,
          freeTrialDaysLeft: this.getJobBoard.freeTrialDaysLeft,
        })

        LogRocket.identify(this.$auth.user().id, {
          name: `${this.$auth.user().firstName} ${this.$auth.user().lastName}`,
          email: this.$auth.user().email,
          jobBoardId: this.$auth.user().jobBoardId,
        })
      }
    }
  },
  methods: {
    ...mapActions([
      'fetchJobBoard',
      'fetchEcommercePreferences',
      'setJobBoard',
    ]),
    signOut() {
      this.$auth.logout({
        makeRequest: false,
        redirect: '/signin',
      })

      this.$_pusher_disconnect()
      this.setJobBoard(null)
    },
    startTopProgress() {
      this.$refs.topProgress.start()
    },
    stopTopProgress() {
      this.$refs.topProgress.done()
    },
    handleGoToRoute({ route, element }) {
      this.$router.push(route, () => {
        if (element) {
          this.handleGoToElement(element)
        }
      })
    },
    handleGoToElement(element) {
      clearTimeout(this.timeout)

      this.timeout = setTimeout(() => {
        const elem = document.querySelector(element)
        if (!elem) {
          return this.handleGoToElement(element)
        }
        VueScrollTo.scrollTo(element, 300, {
          easing: 'linear',
          offset: -60,
        })
      }, 500)
    },
  },
  watch: {
    getJobBoard() {
      if (this.getJobBoard) {
        const hasTopBanner =
          !this.getJobBoard.subscriptionActive && !this.getJobBoard.revenueShare

        document.documentElement.style.setProperty(
          '--body-offset',
          `${hasTopBanner ? 95 : 55}px`
        )
      }
    },
  },
}
</script>
<style>
:root {
  --body-offset: 55px;
}

.v-tooltip__content {
  opacity: 1 !important;
}

.v-toolbar__content,
.v-toolbar__extension {
  padding: 0 26px !important;
}

.v-toolbar {
  box-shadow: none !important;
  border-bottom: 1px solid #e7e7e7 !important;
  z-index: 100 !important;
}

/* TODO: Change font color */
.theme--light.v-application {
  color: #51586a !important;
}

.v-toolbar .v-avatar {
  border-radius: 4px !important;
  margin-right: 10px !important;
  max-height: 40px !important;
  max-width: 40px !important;
}

.v-toolbar .v-avatar .headline {
  font-size: 20px !important;
}

.v-text-field.v-text-field--single-line.small-text-field .v-input__slot {
  max-width: 150px !important;
}

.logo {
  max-height: 25px;
}

.application .page-loader {
  position: fixed;
  top: 55px;
  margin: 0px;
}

.sub-banner {
  position: fixed;
  background-color: black;
  height: 40px;
  width: 100%;
  z-index: 150;
  left: 0;
  top: 0;
  text-align: center;
  color: #fff;
}
.sub-banner div {
  line-height: 40px;
}

.jobBoard__switch {
  max-width: 180px !important;
}

.jobBoard__switch .v-select__selections {
  flex: 1 !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.jobBoard__switch .v-input__control {
  min-height: 40px !important;
}

.container {
  padding: 24px !important;
}
.subheading {
  font-size: 16px !important;
  font-weight: 400 !important;
}
.v-list--dense .v-list-item,
.v-list-item--dense {
  font-size: 13px !important;
}
.toolbar.v-navigation-drawer {
  width: 80px !important;
}
.toolbar .v-navigation-drawer__content {
  overflow-y: hidden !important;
}
.toolbar .v-navigation-drawer__content .v-list-item {
  height: 54px !important;
}
.toolbar.v-navigation-drawer .v-list-item__action {
  min-width: 48px !important;
}
.toolbar.v-navigation-drawer .v-list-item__action .v-icon {
  font-size: 24px !important;
  display: block;
  margin: auto;
}
.v-list-item
  .v-list-item__action
  .v-icon:not(
    .v-list-item--active .v-list-item__action .v-icon,
    .home-page .v-list-item__action .v-icon
  ) {
}
/* .theme--light.v-list-item--active:before {
  opacity: 0!important;
} */
.toolbar.v-navigation-drawer .v-list-item.v-list-item--link:before {
  top: 2px;
  height: 50px;
  width: 50px;
  border-radius: 8px;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
  color: #f5f5f5;
}

.toolbar.v-navigation-drawer .v-list-item:hover::before,
.toolbar.v-navigation-drawer .v-list-item--active:hover::before,
.toolbar.v-navigation-drawer .v-list-item--active::before {
  opacity: 1;
}

.toolbar.v-navigation-drawer .v-list-item--link .v-list-item__action,
.toolbar.v-navigation-drawer .v-list-item--link .v-list-item__content {
  z-index: 2;
}

.toolbar.v-navigation-drawer {
  padding-top: 12px;
}

.main.v-main {
  padding: 55px 0px 0px 80px !important;
}

.slide-enter-active,
.slide-leave-active {
  transition: all 300ms ease-in;
}
.slide-leave-to,
.slide-enter {
  opacity: 0;
  transform: translateY(20px);
}

@media (max-width: 959px) {
  .v-toolbar__content,
  .v-toolbar__extension {
    padding: 0 16px !important;
  }
}
</style>
